import React, { useState } from "react";
import allPokeJa from "../data/all_poke_ja.json"; // JSONファイルのパスを適切に設定

function SearchBar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const pokeIndex = allPokeJa.findIndex((poke) => poke === searchTerm);

    if (pokeIndex !== -1) {
      const pokeId = pokeIndex + 1;
      onSearch(pokeId.toString());
    } else {
      // 検索語句が数字（ID）の場合、そのまま使用
      if (!isNaN(searchTerm)) {
        onSearch(searchTerm);
      } else {
        // 数字でない場合、英語名とみなしてそのまま使用
        onSearch(searchTerm);
      }
    }
  };

  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit}>
        <div className="flex items-center border-b border-teal-500 py-2">
          <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="ポケモンのなまえ" value={searchTerm} onChange={handleChange} />
          <button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" type="submit">
            けんさく
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
