import React, { useState, useEffect } from "react";
import allTypes from "../data/all_types.json"; // all_types.jsonのパスを適切に設定
import typeNamesJa from "../data/all_type_ja.json"; // all_type_ja.jsonのパスを適切に設定

function PokemonInfo({ pokemon }) {
  const [japaneseName, setJapaneseName] = useState("");
  const [types, setTypes] = useState([]);
  const [effectiveDefenseTypes, setEffectiveDefenseTypes] = useState({
    "×4": new Set(),
    "×2": new Set(),
    "×0.5": new Set(),
    "×0.25": new Set(),
    "×0": new Set(),
  });
  const displayOrder = ["×4", "×2", "×0.5", "×0.25", "×0"];

  useEffect(() => {
    const fetchSpecies = async () => {
      if (pokemon) {
        try {
          const speciesResponse = await fetch(pokemon.species.url);
          const speciesData = await speciesResponse.json();
          const japaneseSpeciesName = speciesData.names.find((name) => name.language.name === "ja");
          setJapaneseName(japaneseSpeciesName ? japaneseSpeciesName.name : pokemon.name);
        } catch (error) {
          console.error("種の情報の取得に失敗しました:", error);
        }
      }
    };

    const fetchTypes = async () => {
      if (pokemon) {
        try {
          const typePromises = pokemon.types.map(async (typeSlot) => {
            const typeResponse = await fetch(typeSlot.type.url);
            const typeData = await typeResponse.json();
            const japaneseTypeName = typeData.names.find((name) => name.language.name === "ja").name;
            const englishTypeName = typeData.name; // 英語名はtypeDataから直接取得可能
            return { english: englishTypeName, japanese: japaneseTypeName };
          });
          const typesData = await Promise.all(typePromises);
          setTypes(typesData); // { english, japanese } オブジェクトの配列をステートに設定
        } catch (error) {
          console.error("タイプの情報の取得に失敗しました:", error);
        }
      }
    };

    // const fetchTypes = async () => {
    //   if (pokemon) {
    //     try {
    //       const typePromises = pokemon.types.map(async (typeSlot) => {
    //         const typeResponse = await fetch(typeSlot.type.url);
    //         const typeData = await typeResponse.json();
    //         const japaneseTypeName = typeData.names.find((name) => name.language.name === "ja");
    //         return japaneseTypeName ? japaneseTypeName.name : typeSlot.type.name;
    //       });
    //       const typesData = await Promise.all(typePromises);
    //       setTypes(typesData);
    //     } catch (error) {
    //       console.error("タイプの情報の取得に失敗しました:", error);
    //     }
    //   }
    // };

    function calculateEffectiveTypes() {
      // 各タイプに対する最終倍率を初期化
      const typeMultipliers = {};

      // all_types.jsonから各タイプに対する基本倍率を設定
      Object.keys(allTypes).forEach((type) => {
        typeMultipliers[type] = 1; // 倍率の初期値は1
      });

      // ポケモンの各タイプに対する防御倍率を計算
      pokemon.types.forEach((pokemonType) => {
        const defenses = allTypes[pokemonType.type.name].defense;

        // 二倍、半分、ゼロになるタイプに対する処理
        defenses.double.forEach((type) => {
          typeMultipliers[type] *= 2;
        });
        defenses.half.forEach((type) => {
          typeMultipliers[type] *= 0.5;
        });
        defenses.zero.forEach((type) => {
          typeMultipliers[type] = 0;
        });
      });

      // 最終的な弱点と耐性を計算して集約
      const finalMultipliers = {};
      Object.entries(typeMultipliers).forEach(([type, multiplier]) => {
        if (multiplier !== 1) {
          // 等倍でない場合に集約
          const key = `×${multiplier}`;
          if (!finalMultipliers[key]) {
            finalMultipliers[key] = [];
          }
          // 英語のタイプ名を日本語に変換して追加
          const typeNameJa = typeNamesJa[type] || type; // 日本語名がない場合は英語名をそのまま使用
          finalMultipliers[key].push(typeNameJa);
        }
      });
      // const finalMultipliers = {};
      // Object.entries(typeMultipliers).forEach(([type, multiplier]) => {
      //   if (multiplier !== 1) {
      //     // 等倍でない場合に集約
      //     const key = `×${multiplier}`;
      //     if (!finalMultipliers[key]) {
      //       finalMultipliers[key] = [];
      //     }
      //     finalMultipliers[key].push(type);
      //   }
      // });

      // ステートを更新
      setEffectiveDefenseTypes(finalMultipliers);
    }

    fetchSpecies();
    fetchTypes();
    if (pokemon) {
      calculateEffectiveTypes();
    }
  }, [pokemon]);

  return (
    <div className={`py-4 px-4 ${types.map((type) => `pokemon-${type.english.toLowerCase()}`).join(" ")}`}>
      {pokemon ? (
        <div className="text-center">
          <h2 className="text-lg font-bold">{japaneseName}</h2>
          <p>
            <img className="inline" src={pokemon.sprites.front_default} alt={japaneseName} />
          </p>
          <h3 className="font-bold mt-2">タイプ</h3>
          <ul>
            {types.map((type, index) => (
              <li className={`type-${type.english.toLowerCase()}`} key={index}>
                {type.japanese}
              </li>
            ))}
          </ul>
          <h3 className="font-bold mt-2">じゃくてん・たいせい</h3>
          <div className="inline-block">
            <ul className="text-left">{displayOrder.map((multiplier) => effectiveDefenseTypes[multiplier] && effectiveDefenseTypes[multiplier].length > 0 && <li key={multiplier}>{`${multiplier}：${effectiveDefenseTypes[multiplier].join(", ")}`}</li>)}</ul>
          </div>
        </div>
      ) : (
        <p>ポケモンをけんさくしてください。</p>
      )}
    </div>
  );
}

export default PokemonInfo;
