import React, { useState } from "react";
import SearchBar from "./components/SearchBar";
import PokemonInfo from "./components/PokemonInfo";

function App() {
  const [pokemon, setPokemon] = useState(null);
  const [error, setError] = useState("");

  const searchPokemon = async (searchTerm) => {
    console.log(searchTerm);
    setError(""); // エラーメッセージをリセット
    if (!searchTerm) {
      setError("検索語を入力してください。");
      return;
    }

    try {
      const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${searchTerm.toLowerCase()}`);
      if (!response.ok) {
        setError("ポケモンが見つかりませんでした。");
        setPokemon(null);
        return;
      }
      const data = await response.json();
      setPokemon(data);
    } catch (error) {
      setError("データの取得中にエラーが発生しました。");
    }
  };

  return (
    <div className="container mx-auto px-4 py-4">
      <h1 className="text-xl font-bold text-center text-gray-800">ポケモンのじゃくてん</h1>
      <SearchBar onSearch={searchPokemon} />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <PokemonInfo pokemon={pokemon} />
    </div>
  );
}
export default App;
